import styled, { css } from 'styled-components';

const SvgWrapper = styled.i<{
  position?: string;
  $square?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  color?: string;
  size?: string;
}>`
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  border-color: inherit;
  line-height: 0;
  width: ${props => props.size || '2.5rem'};
  height: ${props => props.size || '2.5rem'};
  position: ${props => props.position};
  overflow: hidden;

  ${({ color }) => color && `color: ${color};`}

  ${props =>
    // Default: Circle
    !props.$square &&
    css`
      overflow: hidden;
      border-radius: 50%;
    `};
  ${props =>
    props.$square &&
    css`
      border-radius: 0.5rem;
    `};

  svg {
    width: 100%;
    height: 100%;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        opacity: 0.38;
      }
    `};
`;

SvgWrapper.displayName = 'SocialIcons';

export default SvgWrapper;
