import { useBrowserEffect } from '@mnd-frontend/hooks';
import { createContext, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { SPACING_1, SPACING_3, SPACING_6 } from '../../theme';
import { UiLibModal } from '../../UiLibModal';
import { Button } from '../Button';
import { Card } from '../Card';
import Icons from '../Icons';
import { Text } from '../Text';
import media from '../utils/media';

const CloseButtonWrapper = styled.div`
  position: absolute;
  line-height: 0;
  z-index: 1;
  top: ${SPACING_3};
  right: ${SPACING_3};
`;

const BoxStyles = styled(Card)<{
  $size: ModalProps['size'];
  $maxHeight?: string;
  $fixedHeight?: boolean;
  $enableOverflow?: boolean;
}>`
  --card-max-height: ${props => props.$maxHeight ?? '75vh'};
  display: flex;
  flex-direction: column;
  height: ${props => (props.$fixedHeight ? 'var(--card-max-height)' : '100%')};
  width: ${props => (props.$size === 'sm' ? '35rem' : props.$size === 'md' ? '50rem' : '64rem')};
  max-width: 95vw;
  max-height: var(--card-max-height);
  overflow: ${({ $enableOverflow }) => ($enableOverflow ? 'visible' : 'hidden')};
  ${media.lessThan('phablet')} {
    --card-max-height: 95vh;
  }
`;

const BoxForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Body = styled.div<{ $padding?: boolean }>`
  padding: ${props => (props.$padding ? '1rem 1.5rem' : '0')};
  scrollbar-width: thin;
  flex: 1;
  overflow-y: auto;
`;

const Header = styled.header`
  padding: ${SPACING_3} ${SPACING_6} ${SPACING_3} ${SPACING_3};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_1};
  &:has(+ ${Body}) {
    border-bottom: 1px solid ${props => props.theme.colorBorderDividerDefault};
  }
`;

const Footer = styled.footer`
  border-top: 1px solid ${props => props.theme.colorBorderDividerDefault};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING_3};
  padding: 1rem 1.5rem;
`;

interface ModalProps {
  onClose?: () => void;
  size?: 'sm' | 'md' | 'lg';
  openedAsModal: boolean;
  backdropOpacity?: number;
}

interface NonModalProps {
  size?: undefined;
  openedAsModal?: undefined;
  onClose?: undefined;
  backdropOpacity?: undefined;
}

interface RawProps {
  withHeaderAndFooter: false;
  headerContent?: undefined;
  footerContent?: undefined;
  description?: undefined;
  headline?: undefined;
}

interface NormalProps {
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  withHeaderAndFooter?: undefined | true;
  description?: ReactNode;
  headline?: ReactNode;
}

export type BoxProps = {
  loading?: boolean;
  heightLimit?: string;
  children?: ReactNode;
  bodyPadding?: boolean;
  maxHeight?: string;
  fixedHeight?: boolean;
  // Will render the card content wrapped in a form with this submit handler
  onSubmit?: () => void;
  enableOverflow?: boolean;
} & (ModalProps | NonModalProps) &
  (RawProps | NormalProps);

const BoxContext = createContext<
  Pick<BoxProps, 'size'> & {
    footerAndHeaderHeight: number;
    setFooterHeight: (height: number) => void;
    setHeaderHeight: (height: number) => void;
  }
>({
  size: undefined,
  footerAndHeaderHeight: 0,
  setFooterHeight: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  setHeaderHeight: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export const BoxHeader = ({
  children,
  headline,
  description,
}: {
  children: ReactNode;
  description?: ReactNode;
  headline?: ReactNode;
}) => {
  const { size, setHeaderHeight } = useContext(BoxContext);
  const ref = useRef<HTMLDivElement>(null);

  useBrowserEffect(() => {
    if (!ref.current) return;

    const handleResize = () => {
      setHeaderHeight(ref.current?.offsetHeight ?? 0);
    };

    const resizeObserver = new window.ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(ref.current);
    handleResize();
    return () => resizeObserver.disconnect();
  }, [setHeaderHeight]);

  return (
    <Header ref={ref}>
      {headline && (
        <Text
          as="h2"
          variant={
            size === 'sm' ? 'headlineSmall' : size === 'md' ? 'headlineMedium' : 'headlineLarge'
          }
          color="GREY_1000"
        >
          {headline}
        </Text>
      )}
      {description && (
        <Text as="p" variant="body" color="GREY_800">
          {description}
        </Text>
      )}
      {children}
    </Header>
  );
};

export const BoxFooter = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { setFooterHeight } = useContext(BoxContext);

  useBrowserEffect(() => {
    if (!ref.current) return;

    const handleResize = () => {
      setFooterHeight(ref.current?.offsetHeight ?? 0);
    };

    const resizeObserver = new window.ResizeObserver(() => {
      handleResize();
    });
    resizeObserver.observe(ref.current);
    handleResize();
    return () => resizeObserver.disconnect();
  }, [setFooterHeight]);

  return <Footer ref={ref}>{children}</Footer>;
};

export const BoxBody = ({
  children,
  padding = true,
  minHeight,
}: {
  children: ReactNode;
  padding?: boolean;
  minHeight?: string;
}) => {
  const { footerAndHeaderHeight } = useContext(BoxContext);

  // If minHeight is set, we want to make sure that the card does not exceed the max height
  minHeight = minHeight
    ? `min(${minHeight}, calc(var(--card-max-height) - ${footerAndHeaderHeight}px))`
    : undefined;

  return (
    <Body $padding={padding} style={{ minHeight }}>
      {children}
    </Body>
  );
};

export const Box = ({
  openedAsModal,
  onClose,
  size,
  headerContent,
  footerContent,
  headline,
  children,
  bodyPadding,
  description,
  withHeaderAndFooter = true,
  onSubmit,
  maxHeight = '75vh',
  fixedHeight,
  backdropOpacity,
  enableOverflow,
  ...forwardProps
}: BoxProps) => {
  const { t } = useTranslation();
  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const cardChildren =
    typeof openedAsModal === 'boolean' && withHeaderAndFooter ? (
      <>
        {headline || description || headerContent ? (
          <BoxHeader headline={headline} description={description}>
            {headerContent}
          </BoxHeader>
        ) : null}
        {children && <BoxBody padding={bodyPadding}>{children}</BoxBody>}
        {footerContent && <BoxFooter>{footerContent}</BoxFooter>}
      </>
    ) : (
      children
    );
  const potentiallyWrappedInForm = onSubmit ? (
    <BoxForm
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {cardChildren}
    </BoxForm>
  ) : (
    cardChildren
  );

  return (
    <BoxContext.Provider
      value={useMemo(
        () => ({
          size,
          setFooterHeight,
          setHeaderHeight,
          footerAndHeaderHeight: footerHeight + headerHeight,
        }),
        [size, setFooterHeight, setHeaderHeight, footerHeight, headerHeight],
      )}
    >
      {typeof openedAsModal === 'boolean' ? (
        <UiLibModal open={openedAsModal} backdropOpacity={backdropOpacity}>
          <BoxStyles
            {...forwardProps}
            $size={size}
            $maxHeight={maxHeight}
            $fixedHeight={fixedHeight}
            $enableOverflow={enableOverflow}
          >
            {onClose && (
              <CloseButtonWrapper>
                <Button
                  inline
                  configuration="text"
                  type="button"
                  aria-label={t('common_close')}
                  icon={<Icons.Close />}
                  onClick={onClose}
                />
              </CloseButtonWrapper>
            )}
            {potentiallyWrappedInForm}
          </BoxStyles>
        </UiLibModal>
      ) : (
        <Card {...forwardProps}>{potentiallyWrappedInForm}</Card>
      )}
    </BoxContext.Provider>
  );
};
