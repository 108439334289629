import { useStateWithOverwrite } from '@mnd-frontend/hooks';
import { toBoolean } from '@mnd-frontend/ts';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../..';
import {
  GREY_100,
  GREY_1000,
  RADIUS_LG,
  SPACING_1,
  SPACING_2,
  SPACING_3,
  TEAL_100,
  TEAL_700,
} from '../../theme';
import { Box } from '../Box';
import { Button } from '../Button';
import { Chip } from '../Chip';
import Icons from '../Icons';
import { InputChip } from '../InputChip';
import { Text } from '../Text';
import { Highlighter } from '../atoms';

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ModalContent = styled.div`
  display: grid;
  gap: ${SPACING_3};
  padding: ${SPACING_3};
`;

const ChipsWrapper = styled.div<{ $copilot?: boolean }>`
  background: ${({ $copilot }) => ($copilot ? TEAL_100 : GREY_100)};
  padding: ${SPACING_3};
  color: ${({ $copilot }) => ($copilot ? TEAL_700 : GREY_1000)};
  border-radius: ${RADIUS_LG};
  display: grid;
  gap: ${SPACING_2};
`;

const Chips = styled.div<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING_1};
`;

const EmptyResult = styled.p`
  text-align: center;
`;

const WithIcon = styled.span`
  display: flex;
  gap: ${SPACING_1};
  align-items: center;
`;

const ChipsContainer = ({
  topItem,
  temporarySelected,
  setTemporarySelected,
  search,
  copilot = false,
}: {
  topItem: ChipType;
  temporarySelected: ChipType[];
  setTemporarySelected: Dispatch<SetStateAction<ChipType[]>>;
  search: string;
  copilot?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(copilot);

  const isSelected = (item: ChipType) =>
    temporarySelected?.some(selectedItem => selectedItem.value === item.value);

  const handleSelect = (item: ChipType) => {
    setTemporarySelected(prevState => {
      const isAlreadySelected = prevState.find(existingItem => existingItem.value === item.value);

      if (isAlreadySelected) {
        return prevState.filter(existingItem => existingItem.value !== item.value);
      } else {
        return [...prevState, item];
      }
    });
  };

  const isSearching = !!search;

  const childCategories =
    topItem.children?.filter(x => x.label.toLowerCase().includes(search.toLowerCase())) ?? [];

  if (childCategories.length === 0) {
    return null;
  }

  return (
    <ChipsWrapper>
      <Heading onClick={() => setIsOpen(!isOpen)}>
        <WithIcon>
          {copilot && <Icons.Sparkle $color="TEAL_700" $size="xs" />}
          <Text as="h3" variant="headlineSmall">
            {topItem.label}
          </Text>
        </WithIcon>
        {isSearching ? null : isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
      </Heading>
      <Chips $open={isOpen || isSearching}>
        {childCategories.map(childItem => (
          <Chip
            copilot={copilot}
            type="filter"
            onClick={() => handleSelect(childItem)}
            active={isSelected(childItem)}
            key={childItem.value}
          >
            <Highlighter highlight={search}>{childItem.label}</Highlighter>
          </Chip>
        ))}
      </Chips>
    </ChipsWrapper>
  );
};

type ChipType = {
  value: string;
  label: string;
  children?: ChipType[];
};

interface SelectChipModalProps {
  open: boolean;
  onClose: () => void;
  copilotItems?: ChipType[];
  items: ChipType[];
  selectedItems: ChipType[];
  onSelect: (items: ChipType[]) => void;
  headline: string;
  inputLabel: string;
}

const SelectChipModal = ({
  open,
  onClose,
  copilotItems,
  items,
  selectedItems,
  onSelect,
  headline,
  inputLabel,
}: SelectChipModalProps) => {
  const [temporarySelected, setTemporarySelected] =
    useStateWithOverwrite<ChipType[]>(selectedItems);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const hasMatches =
    !search ||
    items
      .map(top => [top.label, top.children?.map(child => child.label)].filter(toBoolean))
      .flat(2)
      .filter(categoryName => categoryName.toLowerCase().includes(search.toLowerCase())).length > 0;

  return (
    <Box
      fixedHeight
      openedAsModal={open}
      onClose={() => {
        onClose();
        setSearch('');
        if (selectedItems !== temporarySelected) setTemporarySelected(selectedItems);
      }}
      headerContent={
        <>
          <Text as="h3" variant="headlineSmall">
            {headline}
          </Text>
          <InputChip
            type="search"
            label={inputLabel}
            selectedItems={temporarySelected}
            searchQuery={search}
            onSearchChange={setSearch}
            onRemove={itemToRemove => {
              setTemporarySelected(prevState =>
                prevState.filter(item => itemToRemove !== item.value),
              );
            }}
          />
        </>
      }
      footerContent={
        <Button
          type="button"
          onClick={() => {
            onSelect(temporarySelected);
            setSearch('');
            onClose();
          }}
        >
          {t('common_select')}
        </Button>
      }
      size="md"
      bodyPadding={false}
    >
      <ModalContent>
        {!hasMatches ? (
          <EmptyResult>{t('common_no_matching_results')}</EmptyResult>
        ) : (
          <>
            {!search &&
              copilotItems?.map(topItem => (
                <ChipsContainer
                  copilot
                  key={topItem.value}
                  topItem={topItem}
                  temporarySelected={temporarySelected}
                  setTemporarySelected={setTemporarySelected}
                  search={search}
                />
              ))}
            {items.map(topItem => (
              <ChipsContainer
                key={topItem.value}
                topItem={topItem}
                temporarySelected={temporarySelected}
                setTemporarySelected={setTemporarySelected}
                search={search}
              />
            ))}
          </>
        )}
      </ModalContent>
    </Box>
  );
};

export default SelectChipModal;
